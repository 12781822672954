import React from 'react'
import cn from 'classnames'

import styles from './Heading.module.css'

type HeadingProps = {
  level?: string
  noMargin?: boolean
  uppercase?: boolean
  centered?: boolean
  color?: string
  style?: React.CSSProperties
  className?: string
}

const Heading: React.FC<HeadingProps> = ({
  children,
  level = '',
  noMargin = false,
  uppercase = false,
  centered = false,
  color = 'dark',
  style = {},
  className,
}) => (
  <header
    style={style}
    className={cn(className, styles.heading, {
      [styles[`level-${level}`]]: level,
      [styles[`color-${color}`]]: color,
      [styles.uppercase]: uppercase,
      [styles.noMargin]: noMargin,
      [styles.centered]: centered,
    })}
  >
    {children}
  </header>
)

export default Heading
