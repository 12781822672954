/* eslint-disable react/no-array-index-key */
import React from 'react'
import cn from 'classnames'
import { i18n } from 'lib/i18n'
import Image from 'next/image'

import Heading from 'components/Heading/Heading'
import Link from 'components/Link/Link'
import useMarketId from 'hooks/useMarketId'

import aftonbladet from './aftonbladet-logo.png'
import bbc from './bbc-logo.png'
import breakit from './breakit-logo.png'
import businessInsider from './businessinsider-logo.png'
import cbc from './cbc-logo.png'
import cityam from './cityam-logo.png'
import dagensmedia from './dagensmedia-logo.png'
import dailymirror from './dailymirror-logo.png'
import dailystar from './dailystar-logo.png'
import didigital from './didigital-logo.png'
import expressen from './expressen-logo.png'
import forbes from './forbes-logo.png'
import friends from './friends-logo.png'
import metronews from './metrologo.png'
import qx from './qx-logo.png'
import resume from './resume-logo.png'
import scotsman from './scotsman-logo.png'
import skynews from './skynews-logo.png'
import theathletic from './the-athletic-logo.png'
import theglobeandmail from './theglobeandmail-logo.png'
import thesun from './thesun-logo.png'
import wired from './wiredlogo.png'

import styles from './PressCoverageLogosOnly.module.css'

const PressCoverageLogosOnly = ({
  title = i18n.t('PressCoverageLogosOnly.headline', 'Press'),
  noTitle = false,
}) => {
  const marketId = useMarketId()
  const articles = [
    {
      image: cbc,
      imageAlt: 'CBC',
      href: 'https://www.cbc.ca/sports/football/cfl/cfl-cflpa-partnerships-memmo-fan-connection-1.5910851',
      markets: ['ca'],
    },
    {
      image: bbc,
      imageAlt: 'BBC',
      href: 'https://www.youtube.com/watch?t=1256&v=fiPFUY3FN5g&feature=youtu.be',
    },
    {
      image: forbes,
      imageAlt: 'Forbes',
      href: 'https://www.forbes.com/sites/igorbosilkovski/2020/04/17/following-cameos-success-swedish-challenger-memmo-starts-conquering-europe-with-personalized-celebrity-videos/',
    },
    {
      image: wired,
      imageAlt: 'Wired',
      href: 'https://www.wired.com/story/on-the-capitalist-internet-even-celebs-join-the-gig-economy/',
    },
    {
      image: theglobeandmail,
      imageAlt: 'The Globe and Mail',
      href: 'https://www.theglobeandmail.com/sports/article-outsourcing-my-mothers-day-gift-to-john-gibbons-paid-off/',
      markets: ['ca'],
    },
    {
      image: businessInsider,
      imageAlt: 'Business Insider',
      href: 'https://www.businessinsider.com/memmo-european-tech-startup-is-swedens-answer-to-cameo-2020-5',
      markets: ['ca', 'global', 'gb'],
    },

    {
      image: skynews,
      imageAlt: 'Skynews',
      href: 'https://news.sky.com/story/why-more-celebrities-are-selling-video-messages-during-pandemic-from-marriage-proposals-to-break-up-requests-12261547',
      markets: ['ca', 'global', 'gb'],
    },
    {
      image: dailystar,
      imageAlt: 'Daily Star',
      href: 'https://www.dailystar.co.uk/real-life/you-can-now-buy-personalised-24296163',
      markets: ['global'],
    },
    {
      image: theathletic,
      imageAlt: 'The Athletic',
      href: 'https://theathletic.com/2276327/2020/12/22/a-holiday-gift-from-borje-salming-ray-ferraro-and-more-for-a-fee/',
      markets: ['ca', 'global'],
      width: '200',
    },

    {
      image: dailymirror,
      imageAlt: 'Daily Mirror',
      href: 'https://www.mirror.co.uk/tech/you-can-pay-uk-celebrities-22993295',
      markets: ['gb', 'au', 'ca', 'dk', 'fi', 'fr', 'de', 'no'],
    },
    {
      image: metronews,
      imageAlt: 'Metro',
      href: 'https://metro.co.uk/2020/12/18/the-office-star-mourns-loss-of-christmas-parties-spoofed-by-show-13769753/',
      markets: ['gb', 'au', 'ca', 'dk', 'fi', 'fr', 'de', 'no'],
    },
    {
      image: scotsman,
      imageAlt: 'The Scotsman',
      href: 'https://www.scotsman.com/news/people/here-are-13-scottish-celebrities-you-can-find-on-cameo-and-memmo-3141038',
      markets: ['gb', 'au', 'ca', 'dk', 'fi', 'fr', 'de', 'no'],
    },
    {
      image: cityam,
      imageAlt: 'CITY A.M.',
      href: 'https://www.cityam.com/pride-and-paradox-how-to-celebrate-business-success-in-the-midst-of-a-pandemic/',
      markets: ['gb', 'au', 'ca', 'dk', 'fi', 'fr', 'de', 'no'],
    },
    {
      image: thesun,
      imageAlt: 'The Sun',
      href: 'https://www.thesun.co.uk/tvandshowbiz/14112811/rod-stewart-tribute-act-birthday-surprise-son-liam/',
      markets: ['gb', 'global'],
    },

    {
      image: breakit,
      imageAlt: 'Breakit',
      href: 'https://www.breakit.se/artikel/23710/sa-gick-memmo-fran-noll-till-hajp-i-hela-sverige-pa-90-dagar',
      markets: ['se'],
    },
    {
      image: aftonbladet,
      imageAlt: 'Aftonbladet',
      href: 'https://www.aftonbladet.se/nojesbladet/a/lA5GKL/kandisarna-samlar-in-pengar--for-att-bekampa-coronakrisen',
      markets: ['se'],
    },
    {
      image: resume,
      imageAlt: 'Resume',
      href: 'https://www.resume.se/marknadsforing/kampanj/de-saljer-videohalsningar-fran-kandisar-for-kommersiellt-bruk/',
      markets: ['se'],
    },
    {
      image: dagensmedia,
      imageAlt: 'Dagens media',
      href: 'https://www.dagensmedia.se/nyheter/under-15/memmos-grundare-vi-har-i-princip-bara-levt-pa-word-of-mouth/',
      markets: ['se'],
    },
    {
      image: qx,
      imageAlt: 'QX',
      href: 'https://www.qx.se/noje/kandis-noje/198748/kandisar-och-hbtq-profiler-stottar-regnbagsfonden/',
      markets: ['se'],
    },

    {
      image: didigital,
      imageAlt: 'DI Digital',
      href: 'https://digital.di.se/artikel/memmos-succestart-darfor-vill-fler-ha-halsning-fran-sina-idoler-under-coronakrisen',
      markets: ['se'],
    },
    {
      image: expressen,
      imageAlt: 'Expressen',
      href: 'https://www.expressen.se/sport/foreningsliv/brand-studio/folkspel/ola-rapace-anna-anka-och-victoria-silvstedt-bara-nagra-av-alla-bakom-mustaschkampen/',
      markets: ['se'],
    },
    {
      image: friends,
      imageAlt: 'Friends',
      href: 'https://friends.se/memmo-och-kanda-profiler-stottar-friends/',
      markets: ['se'],
    },
  ]

  return (
    <section className={styles.wrapper}>
      {!noTitle && (
        <Heading level="h3">
          <h3>{title}</h3>
        </Heading>
      )}

      <div className={styles.logos}>
        {articles.map((article, n) => {
          if (
            Array.isArray(article.markets) &&
            !article.markets.includes(marketId)
          ) {
            return null
          }
          return (
            <Link key={n} href="/press">
              <div
                className={cn(styles.item, {
                  [styles.theAthletic]: article.imageAlt === 'The Athletic',
                })}
              >
                <Image
                  src={article.image}
                  alt={article.imageAlt}
                  width={article.width || '120'}
                  height="60"
                  layout="intrinsic"
                  unoptimized
                  objectFit="contain"
                  objectPosition="center top"
                />
              </div>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default PressCoverageLogosOnly
